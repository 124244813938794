import React from 'react';
import styled,  {css} from "styled-components"
import tw from "tailwind.macro"
import { H3, BodyText, BoldTextWrapper,GrayTextWrapper } from "../elements/shared/texts"
import { AccentLink, OutlineGrayLink} from "../elements/shared/buttons"
import {gradientFlow} from "../styles/Keyframes"
import { GrFormCheckmark } from "react-icons/gr";
import { MotionLinkWrapper } from "../elements/shared/buttons"

const Container = styled.div`
  ${tw`rounded-lg mt-4 relative`};
  background-color: ${props => props.theme.colors.deepBackground};
  border-radius: 20px;
  border-color: ${props => props.theme.colors.border};
  box-shadow: 0 30px 60px ${props => props.theme.colors.mainShadow};
  border-width: 1px;
  padding: 20px;
  overflow: hidden;


  hr{
    border: 0;
    height: 1px;
    margin: 20px 0;

    ${props => props.theme.name === "dark" && css `
        background-image: linear-gradient(to right, #111111, #292f36, #111111);
    `}


    ${props => props.theme.name === "light" && css `
        background-image: linear-gradient(to right, #F1F5F9, #d0d5d7, #F1F5F9);
    `}
  }

  ${props => props.isPremium && css `
        border-color: ${props => props.theme.colors.accent};

        h3{
            color: ${props => props.theme.colors.accent};
        }
  `
}

  .amount {
       font-size: 100px;
       font-weight: bold; 
       line-height: 1em;
  }

  .dollars{
    font-size: 50px;
    font-weight: bold;

  }

`


const PriceContainer = styled.div`
   ${props => props.isPremium && css `
        background: linear-gradient(to right, #F5A623, #D80E70);
        background-size: 300%;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: ${gradientFlow} 20s ease-in-out infinite alternate;
    `}
`

const FeaturesContainer = styled.div`
        p{
          padding: 5px 0;

          svg{
              display: inline;
          }

          svg polyline {
              stroke: ${props => props.theme.colors.primaryText};
          }
      }
`



const PricingCard = props => {


    return (
        <Container  isPremium={props.plan === "PREMIUM"}>

              <div>
                <GrayTextWrapper>
                    <H3>{props.plan}</H3>
                </GrayTextWrapper>
                  <PriceContainer className="price" isPremium={props.plan === "PREMIUM"}>
                    <span className="dollars">$</span><span className="amount">{props.yearly_price / 12}</span>
                  </PriceContainer>
                {
                  props.plan === "PREMIUM" &&  <p>Billed annually or  ${props.monthly_price} billed monthly</p>
                }

              </div>

              <hr />
              <FeaturesContainer>
              <GrayTextWrapper>
                  <BoldTextWrapper>
                      <BodyText>You get:</BodyText>
                  </BoldTextWrapper>
                  </GrayTextWrapper>
                  <div>
                      {props.features.map(feature => (

                        <BodyText key={feature}>
                           <p><GrFormCheckmark /> {feature}</p> 
                        </BodyText>
                      ))}
                  </div>
              </FeaturesContainer>
              <div className="get_started absolute top-0 right-0 p-4">
                <MotionLinkWrapper>
                  { props.plan === "PREMIUM" ?

                        <AccentLink to={`/account/dashboard`}>
                             <BoldTextWrapper>
                            { props.pricingText.premium }
                            </BoldTextWrapper>
                        </AccentLink>

                      :  props.pricingText.free ? <OutlineGrayLink to={`/account/dashboard`}>{props.pricingText.free}</OutlineGrayLink> : null
                    
                   }
                    </MotionLinkWrapper>
              </div>
          </Container>
    )
}

export default PricingCard