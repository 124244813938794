import  React  from "react";
import  Course from "./Course";
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import  SectionHeader from "../components/SectionHeader";
import { PaddingContainer } from "../elements/shared/containers"
import tw from "tailwind.macro"

const BgContainer = styled.div`

`

const Courses = ({courses}) => {

    const data = useStaticQuery (
        graphql`
          query {
            pattern: file(relativePath: { eq: "courses_pattern.svg" }) {
              publicURL
            }
            placeholder: file(relativePath: { eq: "lastpass-clone.jpg" }) {
              publicURL
            }
            previewCourse: file(relativePath: { eq: "preview_course.png" }) {
              publicURL
            }
          }
        `
      )

    return (
      <BgContainer bgImageUrl={data.pattern.publicURL} className={"relative"}>
        <PaddingContainer >
            <div className="container mx-auto">
              <SectionHeader seeAll title="Courses" secondaryText={"Latest"}/>
                    { 
                        courses.map(course => (
                            <Course key={course.node.slug} course={course} />
                        )) 
                        
                    }
            </div>
        </PaddingContainer>
      </BgContainer>
    )
}

export default Courses