import React, { useEffect, useState } from "react";
import { BodyText, BoldTextWrapper, GrayText, HeaderText3, OutlineGrayAnchor } from "../../elements/shared/texts";
import { AccentLink, MotionLinkWrapper } from "../../elements/shared/buttons";
import { UserLoggedInState } from "../../utils/constants";
import { UseGlobalStateContext } from "../../context/globalContext";
import { PaddingContainer } from "../../elements/shared/containers";
import SectionHeader from "../SectionHeader";
import styled from "styled-components";


const BgContainer = styled.div`
  color: ${props => props.theme.colors.primaryText};
  
  .detail {
    background: ${props => props.theme.colors.deepBackground};
    padding: 50px 20px;
    border-radius: 20px;
    border: 0.5px solid ${props => props.theme.colors.border};
    box-shadow: 0 30px 60px ${props => props.theme.colors.mainShadow};
    text-align: center;
    
  }
  
  h4, p{
    width: min(800px, 100%);
    margin: 0 auto;   
  }
  
  h4{
    font-size: 28px;
    font-weight: 300;
    line-height: 1.125em;
    letter-spacing: -0.01em;
  }
  
  p{
    font-size: 20px;
  }
  
  img{
    width: 30%
  }
  
  a {
    width: 200px;
    margin: 30px auto 0 auto;
  }
  
  .detail:nth-child(2){
    margin: min(100px, 20vw) 0;
  }
`


const SupportComponent = props => {
  const [ loggedInState, setLoggedInState ] = useState(UserLoggedInState.LOADING)
  const [buttonText, setButtonText] = useState("")
  const [tagLine, setTagLine] = useState("")
  const { userData } = UseGlobalStateContext()


  // useEffect(() => {
  //   if (userData) {
  //     if (userData.isPremium) {
  //       setButtonText("Dashboard")
  //       // setTagLine("Manage your personal and subscription preferences.")
  //     } else {
  //       setButtonText("Support us now")
  //       // setTagLine(`For only $${data.pricing.premium.monthly_price} a month (or $${data.pricing.premium.yearly_price / 12} billed annually), you will get access to all free and premium courses.`)
  //     }
  //     setLoggedInState(UserLoggedInState.LOGGED_IN)
  //   } else {
  //     setButtonText("Support us now")
  //     // setTagLine(`For only $${data.pricing.premium.monthly_price} a month (or $${data.pricing.premium.yearly_price / 12} billed annually), you will get access to all free and premium courses.`)
  //     setLoggedInState(UserLoggedInState.NOT_LOGGED_IN)
  //   }
  // }, [userData])

  return (

    <BgContainer id="work" >

      <PaddingContainer>

        <div className="container mx-auto ">
          {
            props.isHome &&  <SectionHeader title="Support" secondaryText="my work"/>
          }


    <div className="detail">
      <HeaderText3 isPrimary>Support</HeaderText3>
      <div className={"flex mt-0"}>

        <div>
          <BodyText className="my-4" >
            <h4>  </h4>
          </BodyText>
          <GrayText >
            <p>Everything is provided at no cost.
              However, if you want to support my work, you can do so by signing up for the support plan, which costs only $5 per month. </p>
          </GrayText>

          { loggedInState !== UserLoggedInState.LOADING
          &&
          <MotionLinkWrapper>
            <AccentLink to={`/account/dashboard`} className=" my-4 mx-auto ">
              <BoldTextWrapper>
                <BodyText>{buttonText}</BodyText>
              </BoldTextWrapper>
            </AccentLink>
          </MotionLinkWrapper>
          }
        </div>
      </div>
    </div>
          </div >

      </PaddingContainer>
    </BgContainer>
  )

}

export  default SupportComponent