import  React  from "react";
import styled from "styled-components"
import tw from "tailwind.macro"
import { PaddingContainer } from "../elements/shared/containers"
import  SectionHeader  from "../components/SectionHeader"
import PricingCard from "../components/PricingCard"
import { UseGlobalStateContext } from "../context/globalContext"

const BgContainer = styled.div`
${tw`my-0 sm:my-4 md:my-16`};
  color: ${props => props.theme.colors.primaryText};
`


const Pricing = props => {

  const { userData } = UseGlobalStateContext()
  const [pricingText, setPricingText] = React.useState({})


  React.useEffect (() => {
    if (userData) { 
      if (userData.isPremium) {
        setPricingText(
          {
            premium: "Dashboard",
            free: false
          }
        )
       } else {
        setPricingText(
          {
            premium: "Go premium",
            free: "Dashboard"
          }
        )
       }
    } else {
      setPricingText(
        {
          premium: "Go premium",
          free: "Get started"
        }
      )
    }
  },[userData])

    return (
        <BgContainer id="pricing">
          <PaddingContainer>
            <div className="container mx-auto ">
            <SectionHeader title="Pricing" secondaryText="Our"/>
            <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-6 sm:gap-6 lg:gap-16 grid-cols-1 pt-4">

            <PricingCard {...props.pricing.free} pricingText={pricingText}/>
            <PricingCard {...props.pricing.premium} pricingText={pricingText}/>
            </div>
            </div>
          </PaddingContainer>
      </BgContainer>
    )
}


export default Pricing