import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Courses from "../components/Courses"
import Blog from "../components/Articles"
import WorkComponent from "../components/WorkComponent"
import Pricing from "../components/Pricing"
import SEO from "../components/seo"
import SupportComponent from "../components/Home/Support";




const IndexPage = ({ data }) => {

  return (
    <Layout>
      <SEO/>
      <Header />
      {/* <SupportComponent/> */}
      <Courses courses={data.allContentfulCourse.edges} />
      <Blog  articles={data.allContentfulArticle.edges} />
      {/*<Pricing pricing={data.allContentfulHome.edges[0].node.pricing}/>*/}
      <WorkComponent work={data.allContentfulHome.edges[0].node.work} isHome/>
    </Layout>
  )
}

export const query = graphql`
  {
      allContentfulHome {
          edges {
              node {
                  work {
                      type
                      description
                      note
                  }
                  pricing {
                      free {
                          features
                          plan
                          monthly_price
                          yearly_price
                      }
                      premium {
                          features
                          plan
                          monthly_price
                          yearly_price
                      }
                  }
              }
          }
      }
    allContentfulCourse(
        sort: { fields: createdDate, order: DESC }
        limit: 1
    ) {
      edges {
        node {
          slug
          title
          createdDate(formatString: "dddd, DD MMMM YYYY")
          excerpt
          image
          plan
          videoUrl
          tags {
          title
        }
          previews {
            content
          }
        }
      }
    }
    allContentfulArticle(
        sort: { fields: createdDate, order: DESC } 
        limit: 2) {
      edges {
        node {
          createdDate(formatString: "dddd, DD MMMM YYYY")
          image
          slug
          title
          videoUrl
          tags {
            title
          }
          body {
            childMdx {
              excerpt(pruneLength: 300)
            }
          }
        }
      }
    }
  }
`

export default IndexPage
