import  React from "react"
import { Link } from "gatsby";
import styled from "styled-components"
import Article from "./Article";
import  SectionHeader from "../components/SectionHeader";
import { PaddingContainer } from "../elements/shared/containers"


const BgContainer = styled.div`
`


const Blog = ({articles}) => {

    return (

        <BgContainer>
            <PaddingContainer>
            <div className="container mx-auto">
            <SectionHeader seeAll title="Articles" secondaryText={"Latest"}/>
                <div className="grid  lg:grid-cols-2 gap-6 sm:gap-12 lg:gap-16 grid-cols-1 mt-4">
                    { 
                        articles.map(article => (
                            <Link key={article.node.slug} to={`/article/${article.node.slug}`}>
                                <Article article={article} />
                            </Link>
                        )) 
                        
                    }
                </div>
            </div>
            </PaddingContainer>
        </BgContainer>

    )
}

export default Blog